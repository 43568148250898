var heroSwiper = new Swiper(".heroSwiper", {
    autoplay: {
        delay: 5000,
    },
    speed: 1000,
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
    on: {
        slideChange: function (el) {
          jQuery('.swiper-slide').each(function () {
            var youtubePlayer = jQuery(this).find('iframe').get(0);
            if (youtubePlayer) {
                  youtubePlayer.contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
                //youtubePlayer.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
            }
          });
        },
      },
});

jQuery(".heroSwiper").mouseenter(function(){
    heroSwiper.autoplay.stop();
});

jQuery(".heroSwiper").mouseleave(function(){
    heroSwiper.autoplay.start();
});

var productSwiper = new Swiper(".productSwiper", {
    slidesPerView: 1,
    spaceBetween: 30,
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    breakpoints: {
        640: {
            slidesPerView: 2,
            spaceBetween: 20,
        },
        768: {
            slidesPerView: 2,
            spaceBetween: 20,
        },
        992: {
            slidesPerView: 3,
            spaceBetween: 40,
        },
        1200: {
            slidesPerView: 4,
            spaceBetween: 20,
        },
    },
});


var productSwiper = new Swiper(".rating-slider", {
    slidesPerView: 1,
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    breakpoints: {
        768: {
            slidesPerView: 2,
            spaceBetween: 20,
        },
        1024: {
            slidesPerView: 3,
            spaceBetween: 20,
        },
    },
});

var productDetailsSwiper = new Swiper(".productDetailsSwiper", {
    //loop: false,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
});

var galleryThumbs = new Swiper('.gallery-thumbs', {
    spaceBetween: 0,
    slidesPerView: 4,
    loop: false,
    freeMode: true,
    allowTouchMove: false,
    //touchRatio: 0,
    loopedSlides: 5, //looped slides should be the same
    watchSlidesProgress: true,
    //direction: 'vertical',
    
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
    breakpoints: {
        1400: {
            direction: 'vertical',
            slidesPerView: 3,
        },
    }
  });
  var galleryTop = new Swiper('.gallery-top', {
    spaceBetween: 10,
    loop: false,
    allowTouchMove: false,
    //touchRatio: 0,
    loopedSlides: 5, //looped slides should be the same
    navigation: {
        nextEl: '.swiper-thumb-button-next',
        prevEl: '.swiper-thumb-button-prev',
    },
    thumbs: {
      swiper: galleryThumbs,
    },
  });

var productVideoSlider = new Swiper(".productVideoSlider", {
    slidesPerView: 1,
    loop: true,
    centeredSlides: true,
    //slidesPerView: 'auto',
    spaceBetween: 0,
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    breakpoints: {
        768: {
            slidesPerView: 3,
        }
    },
});

var productFindOurProducts = new Swiper(".productFindOurProducts", {
    slidesPerView: 1,
    breakpoints: {
        640: {
            slidesPerView: 2,
            spaceBetween: 20,
        },
        1024: {
            slidesPerView: 3,
            spaceBetween: 20,
        },
    },
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
});

jQuery('.popup-youtube').magnificPopup({
    //disableOn: 700,
    type: 'iframe',
    mainClass: 'mfp-fade',
    removalDelay: 160,
    preloader: false,
    fixedContentPos: false,
    iframe: {
        markup: '<div class="mfp-iframe-scaler">'+
                  '<div class="mfp-close"></div>'+
                  '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>'+
                '</div>', // HTML markup of popup, `mfp-close` will be replaced by the close button
      
        patterns: {
          youtube: {
            index: 'youtube.com/', // String that detects type of video (in this case YouTube). Simply via url.indexOf(index).
      
            id: 'v=', // String that splits URL in a two parts, second part should be %id%
            // Or null - full URL will be returned
            // Or a function that should return %id%, for example:
            // id: function(url) { return 'parsed id'; }
      
            src: '//www.youtube.com/embed/%id%?autoplay=0&fs=0&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0&origin=https://youtubeembedcode.com' // URL that will be set as a source for iframe.
          },
          vimeo: {
            index: 'vimeo.com/',
            id: '/',
            src: '//player.vimeo.com/video/%id%?autoplay=1'
          },
          gmaps: {
            index: '//maps.google.',
            src: '%id%&output=embed'
          }
      
          // you may add here more sources
      
        },
      
        srcAction: 'iframe_src', // Templating object key. First part defines CSS selector, second attribute. "iframe_src" means: find "iframe" and set attribute "src".
      }
});

jQuery('.popup-modal').magnificPopup({
    type: 'inline',
    preloader: false,
    focus: '#name',

    // When elemened is focused, some mobile browsers in some cases zoom in
    // It looks not nice, so we disable it:
    callbacks: {
        beforeOpen: function() {
            if(jQuery(window).width() < 700) {
                this.st.focus = false;
            } else {
                this.st.focus = '#name';
            }
        }
    }
});

jQuery('.mega-dropdown').each(function(){
    jQuery('> ul', this).wrapAll('<div class="mega-dropdown-wrap"></div>');
});

jQuery('.gallery').each(function() {
    
    jQuery(this).magnificPopup({
        delegate: 'a',
        type: 'image',
        tLoading: 'Loading image #%curr%...',
        mainClass: 'mfp-img-mobile',
        gallery: {
            enabled: true,
            navigateByImgClick: true,
            preload: [0,1] // Will preload 0 - before current, and 1 after the current image
        },
        image: {
            tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
            titleSrc: function(item) {
                //return //item.el.attr('title');
                var gallery = jQuery(item.el).parent().parent();
                var result = '';
                if (gallery.find('li').length>0) {
                    result = '<ul class="dots">';
                    for (var i=0; i<gallery.find('li').length; i++) {
                        var cl_active = '';
                        if (item.index == i) cl_active = ' class="active"'; else cl_active = '';
                        var thumb = gallery.find('li:eq('+i+')').find('img').attr('src');
                        result += '<li'+cl_active+'>' +
                                '<button type="button" onclick="javascript:jQuery(\'.gallery\').magnificPopup(\'goTo\', '+i+');return false;"></button>' +
                            '</li>';
                    }
                    result += '</ul>';
                }
                return result;
            }
        },
        callbacks: {
            buildControls: function() {
                // re-appends controls inside the main container
                this.arrowLeft.appendTo(this.contentContainer);
                this.arrowRight.appendTo(this.contentContainer);
            }
        }
    });
});

jQuery('.tags-filter li').on('click',function(){

    jQuery(this).toggleClass('active');
    var tags = new Array();
    jQuery('.active-tab .tags-filter li.active').each(function(){
        tags.push(jQuery(this).attr('data-tag'));
    });

    //console.log(tags);
    var cat_el;
    var cat_class;
    jQuery('.active-tab .pro-list li').each(function(){
        cat_el = jQuery(this);
        var flag= false;
        jQuery.each(tags, function(index, value){
            cat_class = cat_el.attr('data-tags');

            if(cat_class !== undefined && cat_class.indexOf(value) != -1 ){
                //alert(cat_class);
                flag= true;
            }
        });
        if(flag){
            jQuery(this).addClass('show-item');
        } else{
            jQuery(this).removeClass('show-item');
        }
    }); // end of foreach

    jQuery('.active-tab .product-by-cat article').each(function(){
        if(jQuery('.show-item', this).length > 0){
            jQuery(this).show();
        } else{
            jQuery(this).hide();
        }
    });
    if(jQuery('.active-tab .product-by-cat article:visible').size() > 0){

        jQuery('.active-tab .tab-error').hide();

    } else{
        jQuery('.active-tab .tab-error').show();
    }
}); // end of filter click

jQuery('.menu-link').on('click', function(){
    var divId = jQuery(this).attr('href');
    jQuery(divId).addClass('open');
    jQuery('html').addClass('scroll-off');
     return false;
});

jQuery('.menu-close').on('click',function(){
    var divId = jQuery(this).attr('href');
    jQuery(divId).removeClass('open');
    jQuery(this).closest(divId).removeClass('open');
    jQuery('html').removeClass('scroll-off');
        return false;
});


function checkForChanges()
{
    if (jQuery('.si-btn').hasClass('listening')) {
        setTimeout(checkForChanges, 500);
        
    } else {
        jQuery('.searchsubmit').on('click',);
    }
}

jQuery('.si-btn').on('click',function(){
    
    setTimeout(function(){
    
        checkForChanges();
    
    },2000);
    

});

jQuery('.mega-dropdown').hover(
    function(){ jQuery('.header').addClass('menu-hover') },
    function(){ jQuery('.header').removeClass('menu-hover') }
)

jQuery('.accordion-items h2').on('click',function(){
    jQuery(this).siblings('.toggle-item').slideToggle().parent().toggleClass('active');
});

jQuery('.mobile-menu').on('click',function(){
    jQuery(this).toggleClass('active');
    jQuery('.menu-main-menu-container').fadeToggle();
    jQuery('.menu-main-menu-container > #menu-main-menu').css({'max-height' : jQuery(window).height() - 100, 'overflow': 'auto'});
    jQuery('.header').toggleClass('menu-hover');
});

jQuery('<span class="toggle-menu"></span>').insertBefore('.menu-main-menu-container > #menu-main-menu > li.menu-item-has-children > a');

jQuery('.toggle-menu').on('click',function(){
            
    if(jQuery(this).hasClass('active')){
        jQuery(this).removeClass('active');
        jQuery(this).siblings('.mega-dropdown-wrap').slideUp();
        jQuery(this).siblings('ul.sub-menu').slideUp();
    } else {
        jQuery('.mega-dropdown-wrap, #menu-main-menu > li > .sub-menu').slideUp();
        jQuery('.toggle-menu').removeClass('active');
        jQuery(this).addClass('active');
        jQuery(this).siblings('.mega-dropdown-wrap').slideDown()
        jQuery(this).siblings('ul.sub-menu').slideDown();
      
    }
});

jQuery(function($) {

    jQuery(".scroll").click(function(event) {
        event.preventDefault();
		 var divId = jQuery(this).attr('href');
        jQuery("html, body").animate({ scrollTop: jQuery(divId).offset().top }, "slow");
        return false;
    });

    if($(window).width() < 768) {
        $(".widget_nav_menu .widget-title").click(function(event) {
            if($(this).hasClass('opened')){
                $(this).removeClass('opened');
                $('.widget_nav_menu > div').slideUp();
            } else {
                $('.widget_nav_menu .widget-title').removeClass('opened');
                $('.widget_nav_menu > div').slideUp();
                $(this).addClass('opened');
                $(this).siblings('div').slideDown();
            }
        });
    }
    
    jQuery(".parallax").parallaxScroll({
      friction: 0.4,
      direction: "vertical"
    });

    jQuery(".scroll-top").on('click',function(event) {
        event.preventDefault();
        jQuery("html, body").animate({ scrollTop: 0 }, "slow");
        return false;
    });
    jQuery(window).on('scroll', function() {
        
        var height = jQuery(window).scrollTop();
		if (height > 200) {
			jQuery('.scroll-top').fadeIn();
		} else {
			jQuery('.scroll-top').fadeOut();
		}
        if (jQuery(this).scrollTop()>70)
            {
            jQuery('.header').removeClass('fixed');

            clearTimeout(jQuery.data(this, 'scrollTimer'));
            jQuery.data(this, 'scrollTimer', setTimeout(function() {
            jQuery('.header').addClass('fixed');
        }, 3000));

        } else {
            jQuery('.header').addClass('fixed');

        }
	});
  });

